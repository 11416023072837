/* ------------------------------------------------------------------------------------------------ */
/* Responsive */
/* ------------------------------------------------------------------------------------------------ */

@include responsive(900px) {
  .banner {
    &__small {
      position: relative;
      &-left {
        position: relative;
        z-index: 3;
        .content {
          padding: 60px 0; }
        h2 {
          margin-bottom: 0;
          font-size: 32px;
          line-height: 40px;
          padding-right: 30px;
          br {
            display: none; } } }
      &-right {
        position: absolute;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        widht: 100%;
        // height: 100vh
        // max-height: 308px
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255,255,255,0.8); } } } }

  .home {
    &__banner {
      height: calc(100vh - 64px);
      .container {
        max-height: calc(100vh - 64px); }
      .aksen-1 {
        width: 100px;
        height: 100px;
        left: inherit;
        right: 0;
        z-index: 2; }
      &_img {
        height: 100%;
        max-height: calc(100vh - 64px);
        max-width: 100%;
        img {
          max-height: calc(100vh - 64px);
          max-width: 100%; }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255,255,255,0.8); } }
      &_content {
 }        // padding-bottom: 80px
      .heading {
        .banner__logo {
          display: none; }
        h2 {
          font-size: 32px;
          line-height: 40px; } } } }
  .section {
    &.text-wicon {
      padding: 80px 0 !important;
      .aksen {
        display: none; }
      .text {
        p {
          padding: 0; } } }
    &.services {
      .col-md-6 {
        margin-bottom: 24px; } }
    &.testimony {
      .aksen-3 {
        width: 224px;
        height: 224px;
        top: 18%;
        left: 16px; }
      .section__heading_waksen {
        display: none;
        &.mobile {
          display: block !important; } }
      .testimony__photo {
        width: 220px;
        height: 220px;
        margin-bottom: 40px; }
      .testi {
        padding-left: 0;
        margin-top: 0;
        &:before {
          left: 4px; } }

      .slider {
        .owl-nav {
          display: none; }
        .owl-dots {
          text-align: center;
          margin-top: 34px;
          .owl-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #BDBDBD;
            margin: 0 4px;
            &.active {
              background: $primary;
              width: 12px;
              height: 12px; } } } } } }

  .post-tips {
    .slick-mobile {
      width: calc(100% + 24px);
      overflow: hidden;
      .owl-stage-outer {
        overflow: visible; }
      .owl-stage {
        padding-left: 0 !important;
        padding-bottom: 10px; } }
    .post__card {
      // width: 261px
      width: 100%; }
    .recent__tips_item {
      .text {
        h4 {
          br {
            display: none; } } } } }

  .package {
    padding: 60px 0;
    .col-md-6 {
      margin-bottom: 24px; }
    .aksen-4 {
      width: 48px;
      height: 80px;
      top: 0 !important;
      right: 0;
      left: auto !important;
      background-image: url(../images/aksen-4-small.svg); }
    .section__heading_waksen {
      margin-bottom: 32px; }
    &__card {
      .btn__wrap {
        text-align: center;
        display: flex;
        flex-direction: column;
        .btn + .btn {
          margin-top: 16px;
          margin-left: 0; } } }
    .find-uneed {
      display: none; } }

  .having-contarct {
    padding: 60px 0;
    .aksen-3 {
      display: none; }
    &-card {
      margin-bottom: 24px;
      .text {
        h3 {
          font-size: 20px;
          line-height: 24px; } } } }

  .develop {
    .aksen-4 {
      background-image: url(../images/aksen-3-small.svg);
      width: 134px;
      height: 80px;
      bottom: 0;
      transform: rotate(0deg); }
    .section__heading_waksen {
      h3 {
        br {
          display: none; } } }
    &-card {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 24px; } }
  .section_faq {
    &-accordion {
      .card {
        padding: 18px; } } }

  .missions {
    padding-top: 100px;
    .aksen-3 {
      left: 28px;
      top: -27px;
      height: 134px;
      width: 80px; } }

  .since_our {
    padding: 65px 0;
    .aksen-5 {
      width: 48px;
      height: 80px;
      top: 0;
      right: 0; }
    .section__heading {
      padding-top: 0;
      margin-bottom: 45px; }
    &__card {
      margin-bottom: 32px;
      .box-number {
        .number {
          font-size: 64px;
          line-height: 64px;
          sub {
            font-size: 14px;
            line-height: 21px; } } } }
    .col-md-4 {
      &:last-child {
        .since_our__card {
          margin-bottom: 0; } }
      & + .col-md-4 {
        &:before {
          left: -10px; } } } }
  .our_founder {
    .col-lg-3 {
      text-align: center; }
    .aksen-3 {
      top: 0;
      right: 0;
      left: auto;
      bottom: auto;
      width: 80px;
      height: 80px;
      background-image: url(../images/aksen-square-small.svg); }
    &_item {
      margin-bottom: 32px; }
    &_photo {
      width: 136px;
      height: 136px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 24px;
      img {
        width: 100%;
        height: 100%; } }
    &_detail {
      padding-top: 20px;
      .name,
      .pos {
        display: none; } }
    .side-photo {
      vertical-align: middle;
      display: block;
      // max-width: calc(100% - 156px)
      width: 100%;
      max-width: 100%;
      // margin-left: 16px
      margin-left: 0;
      .name {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 4px; } }
    .icon-box {
      display: none;
      &.mobile {
        display: block; }
      .icon {
        width: 24px;
        height: 24px; } } }


  .contact_us {
    .aksen-6 {
      width: 240px;
      height: 80px;
      left: auto;
      right: 0; }
    .row {
      // .col-md-4
      //   order: 2
      // .col-md-7
 }      //   order: 1
    .or_contactus {
      padding-left: 0;
      margin-bottom: 32px; }
    .btn-box {
      padding-bottom: 50px;
      .btn {
        display: block;
        width: 100%;
        text-align: center; } } }

  .head_office {
    padding-top: 20px;
    padding-bottom: 60px;
    .aksen-7 {
      width: 100px;
      height: 60px; }
    .map {
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      max-width: 100%;
      max-height: 170px;
      border-radius: 0;
      iframe {
        border-radius: 0;
        max-width: 100%;
        max-height: 170px; } }
    .box-address {
      padding: 0;
      padding-top: 32px;
      address {
        h5 {
          font-size: 20px;
          line-height: 24px; } } } }

  .package-price {
    &_card {
      max-width: 260px;
      .package {
        &_name {
          font-size: 20px;
          line-height: 24px; }
        &_box {
          .price {
            sup {
              font-size: 24px;
              line-height: 32px; }

            span {
              font-size: 72px;
              line-height: 85px; } } } } }
    .price-mobile {
      overflow: hidden;
      .col-md-4 {
        // padding-left: 0
 }        // padding-right: 0
      .owl-stage-outer {
        overflow: visible; }
      .owl-item {
        padding-top: 5px;
        padding-bottom: 5px; } } } }

@include mobile {
  .our_founder {
    .col-lg-3 {
      text-align: left; }
    .side-photo {
      vertical-align: middle;
      display: inline-block;
      max-width: calc(100% - 156px);
      margin-left: 16px; }
    &_photo {
      margin-bottom: 0; } }
  .post-tips {
    .post__card {
      width: 261px; } }
  .since_our {
    &__card {
      text-align: center; } } }
