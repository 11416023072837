/* ------------------------------------------------------------------------------------------------ */
/* Custom Plugins */
/* ------------------------------------------------------------------------------------------------ */

.collapse {
  display: none;
  &.in,
  &.show {
    display: block; } }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height,visibility;
  transition-duration: .35s;
  transition-timing-function: ease; }


.panel-body {
  .b-category {
    padding: 0;
    padding-top: 12px; } }

.owl-carousel {
  .owl-dots {
    text-align: center;
    margin-top: 34px;
    .owl-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #BDBDBD;
      margin: 0 4px;
      &.active {
        background: $primary;
        width: 12px;
        height: 12px; }
      &:focus {
        box-shadow: none;
        outline: none; } } } }

// MODAL

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;

  &:hover, &:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }

  &:not(:disabled):not(.disabled) {
    cursor: pointer; } }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }

.modal {
  &.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%); }

  &.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0; }

.modal-close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 32px;
  right: 32px;
  background: url(../images/ic-close.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(33,33,33,0.8);

  &.fade {
    opacity: 0; }

  &.show {
    opacity: 1; } }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;

  .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; } }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 32px;
  h3 {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px; }
  h4 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px; }
  .btn-wrap {
    padding-top: 32px; }
  ul {
    margin-top: -16px;
    padding-left: 0;
    li {
      position: relative;
      padding-left: 8px;
      &:before {
        content: "•";
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        position: absolute;
        top: 0;
        left: 0; } } } }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;

  > {
     :not(:first-child) {
      margin-left: .25rem; }

     :not(:last-child) {
      margin-right: .25rem; } } }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }

  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }
