/* ------------------------------------------------------------------------------------------------ */
/* Fonts */
/* ------------------------------------------------------------------------------------------------ */

@include fontGenerator("Montserrat Regular",(400),(montserrat-regular));
@include fontGenerator("Montserrat Medium",(500),(montserrat-medium));
@include fontGenerator("Montserrat Semibold",(600),(montserrat-semibold));
@include fontGenerator("Montserrat Bold",(700),(montserrat-bold));

@include fontGenerator("Roboto",(400),(roboto-regular));


@mixin font-400 {
  font-family: "Roboto", sans-serif;
  font-weight: 400; }

@mixin font-500 {
  font-family: "Montserrat Medium", sans-serif;
  font-weight: 500; }

@mixin font-600 {
  font-family: "Montserrat Semibold", sans-serif;
  font-weight: 600; }

@mixin font-700 {
  font-family: "Montserrat Bold", sans-serif;
  font-weight: 700; }
