/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

label {
  display: block;
  margin-bottom: 4px; }

.form-control {
  display: block;
  width: 100%;
  height: 54px;
  padding: 20px;
  font-size: 18px;
  // line-height: 26px
  // color: #fff
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #B7BCBF;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }






@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

.form-control:focus {
  color: $text;
  background-color: #fff;
  border-color: #3A5EC1;
  outline: 0;
 }  // box-shadow: 0 0 0 4px rgba(58,94,193,0.25)

.form-control::-webkit-input-placeholder {
  color: #BDBDBD;
 }  // opacity: .6

.form-control::-moz-placeholder {
  color: #BDBDBD;
 }  // opacity: .6

.form-control:-ms-input-placeholder {
  color: #BDBDBD;
 }  // opacity: .6

.form-control::-ms-input-placeholder {
  color: #BDBDBD;
 }  // opacity: .6

.form-control::placeholder {
  color: #BDBDBD;
 }  // opacity: .6

.form-control:disabled, .form-control[readonly] {
  background-color: #BDBDBD;
  opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 24px;
  .btn {
    padding: 12px 25px; }
  &.currency {
    position: relative;
    span {
      display: inline-block;
      position: absolute;
      opacity: 0.5;
      left: 13px;
      top: 15px; }
    .form-control {
      padding-left: 50px; } } }
.form-action {
  padding-top: 16px;
  .btn {
    padding: 14px 25px; } }

.input-group {
  position: relative;
  &-addon {
    position: absolute;
    line-height: 46px;
    padding: 0 12px;
    top: 0;
    &:first-child {
      left: 0; }
    &:last-child {
      right: 0; } }
  .form-control {
    &:not(:first-child) {
      padding-left: 32px; }
    &:not(:LAST-child) {
      padding-right: 32px; } } }
.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px; }


.custom-control {
  display: block;
  margin: 0;
  padding: 7px 0;
  &-label {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    display: block;
    padding: 1px 0 1px 32px;
    margin: 0;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: rem(20);
      height: rem(20);
      position: absolute;
      top: calc(50% - #{rem(10)});
      left: 0;
      border: 1px solid #D3D7D8;
      border-radius: 2px;
      background: #FFF; }
    &:after {
      content: "";
      display: block;
      width: rem(20);
      height: rem(20);
      position: absolute;
      top: calc(50% - #{rem(10)});
      left: 0;
      border: 1px solid $primary;
      border-radius: 2px;
      background: $primary url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='48px' height='48px' viewBox='0 0 48 48' enable-background='new 0 0 48 48' xml:space='preserve'%3E%3Cpolygon fill='%23FFFFFF' points='48,9.748 44.952,6.701 16.495,35.158 3.048,21.71 0,24.759 13.447,38.206 16.495,41.253 16.541,41.299 19.589,38.252 19.542,38.206 '/%3E%3C/svg%3E") no-repeat center;
      background-size: 16px;
      transform: scale(.5);
      opacity: 0;
      transition: all .2s ease-in-out; } }
  &-input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    &:checked + .custom-control-label:after {
      transform: scale(1);
      opacity: 1; } } }


.dropdown {
  position: relative;
  color: inherit;
  select {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 0.5px;
    height: 100%;
    padding: 0;
    opacity: 0;
    border: none;
    visibility: hidden; }
  .btn {
    cursor: pointer;
    background: none;
    border: 0;
    border: 1px solid $border;
    border-radius: 8px;
    font-size: 16px;
    line-height: 1.3em;
    height: 54px;
    width: 100%;
    text-align: left;
    padding: 20px;
    padding-right: 20px;
    outline: none !important;
    color: inherit;
    &.bs-placeholder {
      color: $border;
      opacity: .5; }
    &:after,
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 3.5px);
      right: rem(20);
      @include triangle('down', 12px, 6px, #ffffff); }
    &:after {
      @include triangle('down', 12px, 6px, #4E4F52);
      top: calc(50% - 3.5px); }
    &:focus,
    &:active {
      // border-color: #3A5EC1
      outline: 0;
 } }      // box-shadow: 0 0 0 4px rgba(58,94,193,0.25)
  div.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $border;
    border-radius: 4px;
    margin: 2px 0 0;
    width: 100%;
    min-width: 100px;
    box-shadow: 0 16px 40px 0 rgba(0,0,0,0.25);
    padding: 8px 0;
    &.inner.show {
      list-style: none;
      padding-left: 0; }
    a {
      display: block;
      padding: 6px 12px;
      color: $text;
      font-weight: 400;
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      &:hover {
        background: #F2F2F2; } }
    li.selected {
      background: $primary;
      a {
        color: $dark;
        &:hover {
          background: $primary; } } }
    &.show {
      display: block; }
    ul.dropdown-menu {
      &.inner {
        list-style: none;
        padding-left: 0; } } } }


.form-contact {
  .wpforms-form {
    .wpforms-field {
      padding: 0 !important;
      &.email,
      &.phone {
        width: calc(50% - 10px);
        display: inline-block;
        @include mobile {
          width: 100%;
          display: block;
          margin-left: 0 !important;
          margin-right: 0 !important; } }
      &.email {
        margin-right: 10px; }
      &.phone {
        margin-left: 10px; }
      @extend .form-group;

      .wpforms-field-medium {
        @extend .form-control;
        max-width: 100% !important; }
      textarea.wpforms-field-medium {
        min-height: 192px; } }

    .wpforms-submit-container {
      text-align: right;
      .wpforms-submit {
        padding: 12px 40px;
        border-radius: 8px;
        display: inline-block;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        transition: all .3s ease;
        background-color: $primary;
        color: #fff;
        border: none !important;
        &:hover {
          text-decoration: none;
          color: $text;
          color: #fff;
          background-color: darken($primary, 10%); }
        @include mobile {
          display: block;
          width: 100%; } } } }

  .wpforms-recaptcha-container.wpforms-is-recaptcha {
    display: flex;
    justify-content: flex-end; } }







