
.section.blog-content {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #fff;
  overflow-x: hidden;
  .mb-20 {
    margin-bottom: 20px !important; }
  .mb-40 {
    margin-bottom: 40px !important; }
  .mt-40 {
    margin-top: 40px !important; }
  .mt-60 {
    margin-top: 60px !important; }

  .display-4 {
    font-size: 3.5rem; }
  .display-1,
  .display-2,
  .display-3,
  .display-4 {
    font-weight: 600; }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.5em;
    margin-bottom: .5rem; }

  address, dl, ol, p, ul {
    margin-bottom: 16px; }

  article {
    p {
      font-size: 16px;
      line-height: 1.5em;
      text-align: justify; } }
  article ul li,
  article ol li {
    margin-bottom: 0 !important; }

  .lb-custom--cat-post {
    a {
      display: inline-block;
      margin-right: 15px; } }

  .lb-custom--card .lb-custom--card-img a,
  .lb-custom--post-image {
    display: block;
    position: relative;
    background-position: center;
    width: 100%;
    height: auto;
    background-size: cover;
    padding: 0 0 50%; }

  .lb-custom--categories,
  .lb-custom--recent-posts {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 0; } }
  .lb-custom--categories li a,
  .lb-custom--recent-posts li a {
    display: block;
    padding: 10px 20px;
    border-left: 1px solid #dddddd;
    color: #333333;
    &:hover {
      text-decoration: none;
      border-left: 2px solid #0facf3;
      padding-left: 19px; } }

  .article {
    a {
      color: #212121;
      border-bottom: 1px solid #3276b2; } }
  a {
    img.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto; } }

  .post-tags {
    margin-top: 50px;
    a {
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 4px;
      background-color: #f5f6f7;
      border-radius: 10rem;
      padding: 6px 16px 7px;
      font-weight: 400;
      line-height: 1.3;
      font-size: 85%;
      color: #788394;
      &:hover {
        color: $text;
        text-decoration: none; } } }

  .lb-custom--grid-image,
  .lb-custom--card .lb-custom--card-img {
    box-shadow: 0 3px 12px -1px rgba(7, 10,25, .2), 0 22px 27px -20px rgba(7, 10, 25 , 0.2);
    transition: all .3s ease; }
  .lb-custom--card .lb-custom--card-img a,
  .lb-custom--post-image {
    display: block;
    position: relative;
    background-position: center;
    width: 100%;
    height: auto;
    background-size: cover;
    padding: 0 0 50%; }
  .card-title {
    font-size: 19px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: .5px;
    border-bottom: #f1f2f3;
    a {
      color: #212121;
      &:hover {
        text-decoration: none; } } }

  .lb-custom--grid .card-block p,
  .lb-custom--grid .card-block,
  .lb-custom--card .lb-custom--card-excerpt p {
    display: block;
    text-align: justify;
    text-justify: inter-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 0; }

  .lb-custom--grid .card-block p,
  .lb-custom--grid .card-block,
  .lb-custom--card .lb-custom--card-excerpt p {
    display: block;
    text-align: justify;
    text-justify: inter-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    max-height: 69px;
    span {
      font-size: 16px !important; } }

  .more-link {
    display: none; }

  .text-uppercase {
    text-transform: uppercase!important; }
  .text-light {
    color: #788394 !important; }
  .ls-1, .letter-spacing-1 {
    letter-spacing: 1px; }
  .fs-11 {
    font-size: 11px !important; }
  .fw-600 {
    font-weight: 600 !important; } }

@include responsive(0, 768px) {
  .hidden-md-up {
    display: none!important; } }
