@import 'cores/mixin';

// Color
$primary      : #0083CB;
$secondary    : #3B4676;
$text         : #727272;
$dark         : #212121;
$border       : #757575;



/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';



/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/custom-plugins';
@import 'components/general';
@import 'components/form';
@import 'components/header';
@import 'components/footer';
@import 'components/content';
@import 'components/blog-content';
@import 'components/responsive';
