@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content; }

  @-moz-keyframes #{$animation-name} {
    @content; }

  @-ms-keyframes #{$animation-name} {
    @content; }

  @-o-keyframes #{$animation-name} {
    @content; }

  @keyframes #{$animation-name} {
    @content; } }

@mixin transform($translate3d) {
  transform: $translate3d;
  -webkit-transform: $translate3d;
  -moz-transform: $translate3d;
  -o-transform: $translate3d;
  -ms-transform: $translate3d; }


@mixin center() {
  align-items: center;
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  box-align: center; }

@mixin responsive($max:0,$min: 0) {
  @if $min == 0 {
    @media(max-width: $max) {
      @content; } }
  @else if $max == 0 {
    @media(min-width: $min) {
      @content; } }
  @else {
    @media(max-width: $max) and  (min-width: $min) {
      @content; } } }
@mixin mobile() {
  @include responsive(767px) {
    @content; } }
@mixin maxtablet() {
  @include responsive(991px) {
    @content; } }
@mixin mintablet() {
  @include responsive(0,768px) {
    @content; } }
@mixin minltablet() {
  @include responsive(0,992px) {
    @content; } }
@mixin tablet() {
  @include responsive(992px,768px) {
    @content; } }
@mixin tablets() {
  @include responsive(1200px,768px) {
    @content; } }
@mixin ltablet() {
  @include responsive(1200px,992px) {
    @content; } }
@mixin sdekstop() {
  @include responsive(1200px,1024px) {
    @content; } }
@mixin large() {
  @include responsive(0,1200px) {
    @content; } }
@mixin mac14() {
  @include responsive(1600px,1300px) {
    @content; } }
@mixin imac() {
  @include responsive(1700px,1600px) {
    @content; } }
@mixin imac19() {
  @include responsive(0,1700px) {
    @content; } }
//IE8

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

@mixin bgcenter {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee; }

@mixin bgcontain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
 }  // background-color: #eee

@mixin imgBg($padding) {
  background-size: cover;
  background-position: center;
  background-color: #eee;
  background-repeat: no-repeat;
  display: block;
  &:before {
    content: "";
    display: block;
    padding: $padding 0; } }

@mixin absfull() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

@mixin clear {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin fontGenerator($name,$weight,$family) {
  @for $i from 1 through length($family) {
    $src: #{"../fonts/"}#{nth($family, $i)};
    $n: #{nth($family, $i)};
    @font-face {
      font-family : $name;
      src         : local($n);
      src         : url("#{$src}.eot");
      src         : url("#{$src}.eot#iefix") format("embedded-opentype"), url("#{$src}.woff2") format("woff2"), url("#{$src}.woff") format("woff"), url("#{$src}.ttf") format("truetype"), url("#{$src}.svg##{nth($family, $i)}") format("svg");
      font-style  : normal;
      font-weight : nth($weight, $i);
      font-display: swap; } } }

@mixin triangle($direction,$width,$height,$color) {
  $w2 : $width/2;
  @if not index("up" "up-right" "right" "down-right" "down" "down-left" "left" "up-left",$direction) {
    @error "Direction must be `up`, `up-right`, `right`, `down-right`,`down`, `down-left`, `left` or `up-left`."; }
  @else if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in the `triangle` mixin."; }
  @else {
    border-style: solid;
    height: 0;
    width: 0;

    @if $direction == "up" {
      border-color: transparent transparent $color;
      border-width: 0 ($w2) $height; }
    @else if $direction == "up-right" {
      border-color: transparent $color transparent transparent;
      border-width: 0 $width $width 0; }
    @else if $direction == "right" {
      border-color: transparent transparent transparent $color;
      border-width: ($height / 2) 0 ($height / 2) $width; }
    @else if $direction == "down-right" {
      border-color: transparent transparent $color;
      border-width: 0 0 $width $width; }
    @else if $direction == "down" {
      border-color: $color transparent transparent;
      border-width: $height ($width / 2) 0; }
    @else if $direction == "down-left" {
      border-color: transparent transparent transparent $color;
      border-width: $width 0 0 $width; }
    @else if $direction == "left" {
      border-color: transparent $color transparent transparent;
      border-width: ($height / 2) $width ($height / 2) 0; }
    @else if $direction == "up-left" {
      border-color: $color transparent transparent;
      border-width: $width $width 0 0; } } }

@mixin ellipsis($width: 100%,$display: inline-block) {
  display: $display;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

@mixin SFUI {
  font-family: "SF UI Display", sans-serif; }

@function rem($val) {
  $remVal: ($val / 16) + rem;
  @return $remVal; }
