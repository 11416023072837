/* ------------------------------------------------------------------------------------------------ */
/* Animation */
/* ------------------------------------------------------------------------------------------------ */

/* Feel free to change duration */

.animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

/* .owl-animated-out - only for current item */
/* This is very important class. Use z-index if you want move Out item above In item */

.owl-animated-out {
  z-index: 1; }

/* .owl-animated-in - only for upcoming item */
/* This is very important class. Use z-index if you want move In item above Out item */

.owl-animated-in {
  z-index: 0; }

/* .fadeOut is style taken from Animation.css and this is how it looks in owl.carousel.css: */

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@mixin bounce() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounce {
  0% {
    transform: scale(1,1) translateY(0); }
  10% {
    transform: scale(1.1,.9) translateY(0); }
  30% {
    transform: scale(.9,1.1) translateY(-8px); }
  50% {
    transform: scale(1.05,.95) translateY(0); }
  57% {
    transform: scale(1,1) translateY(-2px); }
  64% {
    transform: scale(1,1) translateY(0); }
  100% {
    transform: scale(1,1) translateY(0); } }
