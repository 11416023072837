/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */
.footer {
  padding: rem(80) 0;
  background: #3B4676;
  color: #FFF;
  &-left {
    ul {
      padding-left: 0;
      li {
        &:not(:last-child) {
          margin-bottom: 4px; }
        a {
          color: #FFF; } } } }
  &-right {
    text-align: right;
    p {
      margin-bottom: rem(8); }
    a {
      color: #FFF; } }
  &-socmed {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: rem(38);
    margin-bottom: rem(38);
    a {
      display: block;
      width: rem(30);
      height: rem(30);
      transition: all .3s ease-in-out;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
      &:hover {
        transform: translateY(rem(-10)); } } }
  &-contact {
    ul {
      list-style: none; } }

  @include mobile {
    text-align: center;
    .col-lg-6 {
      margin-bottom: rem(24); }
    &-right {
      text-align: center; }
    &-socmed {
      justify-content: center; } } }
