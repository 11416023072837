/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */


body {}

.container {
  max-width: 79.688vw; // 1248px //79.792vw
  @include responsive(1600px, 0) {
    max-width: 90vw; }
  @include mobile {
    max-width: 100%; } }
.row {
  margin-left: -12px;
  margin-right: -12px; }

[class*='col-'] {
  padding-left: 12px;
  padding-right: 12px; }

ul {
  // list-style: none
  // margin-bottom: 0
  padding-left: rem(24); }

.disabled {
  opacity: .5;
  pointer-events: none; }

.sticky {
  transition: all .3s ease-in-out; }

main {}

section {
  .section-title {
    padding-bottom: rem(20);
    margin-bottom: rem(40);
    h2 {
      text-align: center;
      position: relative;
      &:after {
        content: "";
        width: rem(90);
        height: 1px;
        background: #000000;
        position: absolute;
        top: calc(100% + 19px);
        left: 0;
        right: 0;
        margin: 0 auto; } } }
  @include maxtablet {
    &:not(.home-masthead):not(.masthead-small) {
      padding: rem(60) 0 !important; }
    .section-title {
      h2 {
        font-size: rem(28);
        line-height: 1.3em; } } }
  @include mobile {
    &:not(.home-masthead):not(.masthead-small) {
      padding: rem(40) 0; } } }

.bg-gray {
  background: #EEEEEE; }

.btn {
  padding: rem(12) rem(38);
  border-radius: 50px;
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  transition: all .3s ease;
  border: 1px solid transparent;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    box-shadow: none;
    outline: none; }
  &:hover {
    text-decoration: none;
    color: $text; }
  &-primary {
    background-color: #0083CB;
    color: #fff;
    &:hover {
      color: #fff;
      background-color: darken(#0083CB, 10%); } }
  &-link {
    color: #3276B2;
    padding: 0;
    &:hover {
      color: #3276B2;
      text-decoration: underline; } }
  &-transparent {
    padding: 12px 34px;
    border: 2px solid #fff;
    border-radius: 8px;
    text-align: center;
    color: #fff;
    &:hover {
      color: $dark;
      background: #fff; } }
  &-large {
    padding: rem(20) rem(50); }
  &-med {
    width: rem(210);
    text-align: center; }

  &-long {
    width: rem(368);
    text-align: center; }

  @include maxtablet {
    &-long {
      width: 100%; } } }

.aksen {
  &-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
    background-image: url(../images/aksen-home-banner.svg);
    @include bgcontain; }
  &-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 320px;
    height: 320px;
    background-image: url(../images/aksen-3.svg);
    @include bgcontain; }
  &-4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 280px;
    background-image: url(../images/aksen-4.svg);
    @include bgcontain; }

  &-5 {
    position: absolute;
    top: calc(50% - 100px);
    right: 0;
    width: 120px;
    height: 200px;
    background-image: url(../images/aksen-5.svg);
    @include bgcontain; }

  &-6 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 360px;
    height: 120px;
    background-image: url(../images/aksen-6.svg);
    @include bgcontain; }
  &-7 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 120px;
    background-image: url(../images/aksen-7.svg);
    @include bgcontain; } }

.section {
  padding: 80px 0;
  position: relative;
  width: 100%;
  overflow: hidden;

  &__heading {
    margin-bottom: 60px;
    text-align: center;
    h3 {
      font-size: 40px;
      line-height: 48px;
      font-family: "Rubik Medium";
      font-weight: 400;
      margin-bottom: 24px;
      margin-bottom: 8px; }
    p {
      font-size: 20px;
      height: 32px;
      color: #616161; }
    &_waksen {
      text-align: left;
      padding-left: 28px;
      position: relative;
      h3 {
        font-size: 40px;
        line-height: 48px;
        font-family: "Rubik Medium";
        font-weight: 400;
        margin-bottom: 24px; }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 100%;
        border-radius: 2px;
        background: #3276B2; }
      span {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        color: #3276B2; }
      &.wsub {
        h3 {
          margin-bottom: 8px; }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #616161; } } } }

  &.text-wicon {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 80px;
    position: relative;
    .aksen {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin-top: auto;
      margin-bottom: auto;
      background-image: url(../images/aksen-2.svg);
      width: 200px;
      height: 280px;
      @include bgcontain; }
    .container {
      &>.row {
        justify-content: center; } }
    .icon {
      width: 160px;
      height: 160px;
      margin: 0 auto 38px;
      img {
        display: block;
        width: 100%;
        height: 100%; }
      &.mobile {
        display: none; } }
    .text {
      width: 100%;
      max-width: 375px;
      margin: 0 auto;
      h4 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px; }
      p {
        margin-bottom: 0;
        padding: 0 22px; } } }

  &.services {
    background: #FAFAFA;
    .aksen-1 {
      left: auto;
      right: 0; }
    .section__heading_waksen {
      margin-bottom: 60px; } }

  &.testimony {
    .container {
      position: relative; }
    .row {
      justify-content: space-between; }
    .testimony__photo {
      width:  240px;
      height: 240px;
      border-radius: 8px;
      overflow: hidden;
      margin-left: auto;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 5; } }
    .testi {
      padding-top: 40px;
      padding-left: 28px;
      padding-right: 40px;
      position: relative;
      margin-top: 150px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 28px;
        width: 27px;
        height: 20px;
        background-image: url(../images/petik.svg);
        @include bgcontain; }
      &__writer {
        .name {
          font-family: "Rubik Medium";
          font-weight: 500;
          margin-bottom: 8px;
          color: $dark; }
        .pos {
          color: #616161;
          margin-bottom: 16px; }
        .logo {
          img {
            max-height: 20px;
            width: auto; } } } }

    .slider {
      .owl-dots {
        width: 100%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
        padding-left: 12px;
        padding-right: 12px;
        @extend .clearfix;
        float: right;
        @include mobile {
          flex: 0 0 100%;
          max-width: 100%; } } } }
  &.post-tips {
    background: #FAFAFA; } }

.masthead {
  &-small {
    position: relative;
    min-height: rem(515);
    .masthead-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      background-attachment: fixed;
      background-position: top;
      background-repeat: no-repeat;
      background-size: auto; }
    .masthead-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #FFF;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .3); }
      h1 {
        color: #FFF;
        margin-bottom: rem(0);
        font-size: rem(50);
        line-height: rem(60);
        @include font-600; }
      .container {
        height: 100%;
        align-items: flex-end;
        padding-bottom: rem(80);
        display: flex;
        position: relative;
        z-index: 4;
        .row {
          width: 100%;
          align-items: flex-end; } } }
    .masthead-date {
       font-size: rem(20);
       line-height: rem(24);
       text-align: right;
       display: block; }
    @include mobile {
      .masthead-text {
        h1 {
          font-size: 36px;
          line-height: 1.3em;
          br {
            display: none; } }
        .row {
          .col-md-6 {
            &:last-child {
              order: 1; }
            &:first-child {
              order: 2; } } } }
      .masthead-date {
        text-align: left; } } } }

.banner {
  &__small {
    padding: 40px 0;
    padding-top: 0;
    .container {
      & > .row {
        align-items: center; } }
    &-left {
      h3 {
        font-size: 16px;
        line-height: 24px;
        color: $primary;
        font-weight: normal;
        margin-bottom: 4px; }
      h2 {
        font-size: 40px;
        line-height: 48px;
        color: $dark;
        margin-bottom: 8px; }
      p {
        font-size: 20px;
        line-height: 32px;
        color: #616161; } } } }


.icon__card {
  width: 100%;
  height: 100%;
  padding: 32px 20px;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
  transition: all .4s linear;
  display: flex;
  flex-direction: column;
  position: relative;
  .card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .icon {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    margin-bottom: 32px;
    overflow: hidden;
    background: #77A1CA;
    display: flex;
    &.mobile {
      display: none; }
    img,
    .svg {
      width: auto;
      max-height: 36px;
      height: 100%;
      display: block;
      object-fit: contain;
      margin: auto;
      filter: brightness(100);
      path {
        fill: red; } } }
  h4 {
    font-size: 20px;
    line-height: 24px;
    font-family: "Rubik Medium";
    font-weight: 500;
    margin-bottom: 12px;
    transition: all .4s linear; }
  .text {
    margin-bottom: 32px;
    p {
      min-height: 100px; } }
  .btn-link {
    display: inline-block;
    margin-top: auto;
    padding: 0;
    text-decoration: none;
    .ic {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      img,
      .svg {
        display: block;
        width: 16px;
        height: 16px;
        max-width: 100%;
        object-fit: contain; } } }
  &:hover {
    background: #3276B2;
    border-color: #3276B2;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
    color: #fff;
    cursor: pointer;
    .icon {
      background: #fff;
      img,
      .svg {
        filter: none; } }
    .btn-link,
    h4 {
      color: #fff; }
    .svg {
      path {
        fill: #fff; } } } }


.post__card {
  position: relative;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
  border-radius: 8px;
  height: 100%;
  a {
    text-decoration: none;
    color: #616161; }
  &_img {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      max-height: 196px;
      object-fit: cover; } }
  &_text {
    padding: 24px 20px;
    padding-bottom: 50px;
    border-radius: 0  0 8px 8px;
    height: 100%;
    max-height: calc(100% - 196px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h4 {
      font-size: 20px;
      line-height: 24px;
      font-family: "Rubik Medium";
      font-weight: 500;
      margin-bottom: 16px; } } }


.recent__tips {
  .section__heading {
    padding-top: 22px;
    margin-bottom: 25px;
    text-align: left;
    h3 {
      margin-bottom: 0; } }
  &_item {
    padding-bottom: 24px;
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 24px;
    a {
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-decoration: none; }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0; }
    .text {
      flex: 0 0 calc(100% - 107px);
      max-width: calc(100% - 107px);
      h4 {
        font-size: 16px;
        line-height: 24px;
        font-family: "Rubik Medium";
        font-weight: 500;
        margin-bottom: 12px; }
      .date {
        color: #BDBDBD;
        font-size: 10px;
        line-height: 12px; } }
    .img {
      width: 96px;
      height: 96px;
      border-radius: 8px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; } } } }

.logo-marquee {
  width: 100%;
  overflow: hidden;
  padding: 0;
  @include mobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }
.logo-grid__container {
  // margin: 2.5rem 0 0
  padding-top: 0;
  padding-bottom: 80px;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    clear: both; } }
.logo-grid {
  display: flex;
  position: relative;
  width: 100vw;
  flex-wrap: wrap;
  .item {
    width: 100%;
    max-width: 10%;
    flex: 0 0 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px 24px;
    img {
      display: block;
      width: 100%;
      height: auto;
      max-height: 64px; } }
  @include mobile {
    &__container {
      padding-top: 0;
      padding-bottom: 60px; }
    .item {
      flex: 0 0 25%;
      max-width: 25%; } } }

.package {
  .section__heading_waksen {
    margin-bottom: 60px; }
  .col-lg-4 {
    margin-bottom: 24px; }
  &__card {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 32px 20px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
    transition: all .4s linear;
    height: 100%;
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 20px;
      line-height: 1.5em;
      font-weight: "Rubik Medium";
      margin-bottom: 8px;
      & + small {
        font-size: 12px;
        line-height: 16px;
        display: block;
        margin-top: -11px;
        margin-bottom: 8px;
        color: #212121; } }
    p {
      max-height: 75px;
      overflow: hidden; }
    .price-box {
      margin-bottom: 35px;
      margin-top: auto;
      .sf {
        display: block;
        font-size: 12px;
        line-height: 1.5em;
 }        // margin-bottom: 8px
      .price {
        font-size: 40px;
        line-height: 1.5em;
        font-weight: bold;
        padding-left: 8px;
        position: relative;
        color: #7CB342; }
      sup {
        font-size: 12px;
        vertical-align: top;
        top: 16px;
        left: -8px;
        font-weight: normal; }
      sub {
        font-size: 12px; } }

    .btn__wrap {
      .btn + .btn {
        margin-left: 24px; }
      .btn-link {
        font-size: 14px; } }
    &:hover {
      color: #fff;
      background: #3276B2;
      border-color: #3276B2;
      cursor: pointer;
      h4 {
        color: #fff;
        & + small {
          color: #fff; } }
      .price-box {
        .price {
          color: #fff; } }
      .btn__wrap {
        .btn {
          &-primary {
            color: $primary;
            background: #fff; }
          &-link {
            color: #fff; } } } } }

  .find-uneed {
    padding: 48px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
    h3 {
      font-size: 38px;
      line-height: 1.5em;
      margin-bottom: 8px; }
    p + .btn {
      margin-top: 24px; }
    .col-md-4 {
      text-align: center; }
    .btn-primary {
      margin: 0 auto; }
    .price-box {
      width: 284px;
      margin: 0 auto;
      p {
        font-size: 20px;
        line-height: 28px;
        color: #616161;
        text-align: left;
        margin-bottom: 0; }
      .price-tag {
        color: #7CB342;
        position: relative;
        sup {
          font-size: 32px;
          line-height: 40px;
          position: absolute;
          top: 0;
          left: 0;
          font-family: "Rubik Medium"; }
        span {
          font-size: 100px;
          line-height: 118px;
          font-family: "Rubik Medium"; } } } } }

.having-contarct {
  background: #FAFAFA;
  .section__heading_waksen {
    margin-bottom: 60px; }
  &-card {
    display: flex;
    margin-bottom: 80px;
    max-width: 572px;
    .icon {
      background: #77A1CA;
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
      max-width: 60px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center; }
    .text {
      margin-left: 20px;
      flex: 0 0 calc(100% - 80px);
      max-width: calc(100% - 80px);
      h3 {
        margin-bottom: 8px; } } } }

.develop {
  .row {
    align-items: center; }
  &-card {
    display: flex;
    margin-bottom: 80px;
    max-width: 572px;
    padding-bottom: 40px;
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0; }
    .icon {
      background: #77A1CA;
      width: 36px;
      height: 36px;
      flex: 0 0 36px;
      max-width: 36px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center; }
    .text {
      margin-left: 24px;
      flex: 0 0 calc(100% - 60px);
      max-width: calc(100% - 60px);
      h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px; } } } }

.section_faq {
  background: $primary;
  .section__heading_waksen {
    h3 {
      color: #fff;
      text-align: center; } }
  &-accordion {
    .card {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #eee;
      background: #fff;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0; }
      .card-header {
        span {
          display: block;
          position: relative;
          padding-right: 32px;
          margin-bottom: 16px;
          &:after {
            content: "";
            width: 24px;
            height: 24px;
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            background-image: url(../images/ic-cev-down-grey.svg);
            @include bgcontain;
            transition: all .4s ease;
            transform: rotate(-90deg); }
          &.collapsed {
            margin-bottom: 0;
            &:after {
              transform: rotate(0deg); } } } } } } }

.missions {
  .aksen-3 {
    height: 200px;
    width: 120px;
    background-image: url(../images/aksen-4-small.svg);
    transform: rotate(90deg);
    left: 40px; }
  .section__heading_waksen {
    margin-bottom: 32px; }
  &__img {
    width: 100%;
    max-width: 460px;
    margin-bottom: 32px;
    img {
      width: 100%;
      height: 100%;
      display: block; } }
  article {
    padding-left: 32px; } }

.since_our {
  background: $primary;
  color: #fff;
  .section__heading {
    padding-top: 40px;
    h3 {
      color: #fff; } }
  .col-md-4 {
    & + .col-md-4 {
      position: relative;
      &:before {
        content: "";
        width: 4px;
        height: 100%;
        border-radius: 2px;
        background: #77A1CA;
        position: absolute;
        top: 0;
        left: -60px; } } }

  &__card {
    padding: 18px 0;
    p {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 0; }
    .box-number {
      .number {
        // font-size: 80px
        // line-height: 80px
        font-size: 72px;
        line-height: 72px;
        font-weight: bold;
        position: relative;
        display: inline-block;
        sup {
          font-size: 32px;
          line-height: 38px;
          position: absolute;
          top: 0;
          right: -32px;
          &.idr {
            right: auto;
            left: 38px;
            font-size: 20px;
            line-height: 24px; } }
        span {
          padding-left: 30px; }
        sub {
          font-size: 20px;
          line-height: 24px;
          position: absolute;
          font-weight: 400;
          bottom: 4px;
          left: calc(100% + 14px); } } } } }

.our_founder {
  .aksen-3 {
    width: 280px;
    height: 280px;
    top: auto;
    bottom: 0; }
  &_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    &:last-child {
      margin-bottom: 0; } }
  &_photo {
    width: 100%;
    flex: 0 0 206px;
    max-width: 206px; }
  &_detail,
  .side-photo {
    // flex: 0 0 calc(100% - 230px)
    // max-width: calc(100% - 230px)
    // padding-left: 24px
    .name {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 0;
      color: #3276B2; }
    .pos {
      display: block;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 16px;
      color: #757575; }
    p {
      color: #616161; } }

  .side-photo {
    display: none; }
  .icon-box {
    .icon {
      width: 32px;
      height: 32px;
      display: inline-block;
      transition: all .4s ease;
      img {
        display: block;
        width: 100%;
        height: 100%; }
      &:hover {
        opacity: .8; } }
    &.mobile {
      display: none; } } }


@include mobile {
  .aksen {
    &-1 {
      width: 80px;
      height: 80px; } }
  .section {
    padding: 80px 0;
    &__heading {
      padding: 0 8px;
      margin-bottom: 60px;
      h3 {
        font-size: 23px;
        line-height: 32px; }
      &_waksen {
        h3 {
          font-size: 23px;
          line-height: 32px; } } }
    &.text-wicon {
      .text-wicon-card {
        display: flex;
        margin-bottom: 32px;
        .icon {
          flex: 0 0 60px;
          max-width: 60px;
          height: 60px; }
        .text {
          margin-left: 16px;
          flex: calc(100% - 76px);
          max-width: calc(100% - 76px);
          text-align: left;
          h4 {
            font-size: 20px;
            line-height: 24px; } } } }
    &.services {
      .col-md-3 {
        margin-bottom: 16px; }
      .services__card {
        .text {
          margin-bottom: 24px; }
        .icon {
          display: none;
          &.mobile {
            width: 48px;
            height: 48px;
            margin-bottom: 20px;
            display: inline-block;
            vertical-align: middle; } }
        h4 {
          max-width: calc(100% - 64px);
          display: inline-block;
          vertical-align: middle;
          margin-left: 12px;
          font-size: 20px;
          line-height: 24px;
          br {
            display: none; } } } } } }

.contact_us {
  background: #FAFAFA;
  padding-bottom: 140px;
  .section__heading_waksen {
    margin-bottom: 64px; }
  .or_contactus {
    padding-left: 30px; }
  h4 {
    font-size: 20px;
    height: 32px;
    font-weight: "Rubik Medium";
    margin-bottom: 24px; }
  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    img {
      display: block;
      width: 100%;
      max-width: 17px;
      max-height: 17px;
      height: 100%; } }
  ul {
    padding-left: 0;
    li {
      margin-bottom: 16px;
      color: #3276B2;
      &:last-child {
        margin-bottom: 0; }
      a {
        color: #3276B2; } } }

  .btn-box {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center; } }

.head_office {
  background: #3276B2;
  color: #fff;
  padding: 0;
  .section__heading_waksen {
    h3 {
      color: #fff; }
    &:before {
      background: #fff; } }
  h5 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 8px;
    .icon {
      width: 36px;
      line-height: 36px;
      display: inline-block;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%; } } }
  p {
    padding-left: 42px; }
  .btn-link {
    color: #fff;
    margin-left: 42px; }
  .box-address {
    // padding: 68px 0
    padding: 30% 0; }

  .map {
    position: absolute;
    top: 20px;
    left: 0;
    width: 53.39%;
    // padding-top: 28.377%
    height: 540px;
    max-height: 100%;
    max-width: 55%;
    border-radius: 0 8px 8px;
    iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 8px 8px;
      overflow: hidden;
      max-width: 100%;
      height: 100%;
      max-height: 100%; } } }

.package-price {
  .col-md-4 {
    &:nth-child(2) {
      .package-price_card {
        // border-width: 4px
        // border-color: #7CB342
        transform: scale(1.05); } } }


  &_card {
    padding: 24px 20px 40px;
    background: #fff;
    // border: 1px solid #BDBDBD
    border: 4px solid transparent;
    box-shadow: 0 0 1px 0 rgba(0,0,0,1);
    border-radius: 8px;
    &:hover,
    &.hover {
      cursor: pointer;
      // background: #7CB342
      border-width: 4px;
      border-color: #7CB342;
      box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);
      // color: #fff
      // .package
      //   &_name
      //     h4
      //       color: #fff
      //       border-color: #fff
      //   &_box
      //     .price
      //       sup,
      //       span
      //         color: #fff
      //     .benefit-list
      //       ul
      //         li
      //           span
      //             color: #fff
      //     .box-btn
      //       .btn
      //         color: #fff
      //       .btn-transparent
      //         background: #fff
      //         color: #7CB342
 }      //         border-color: #fff



    .package {
      &_name {
        h4 {
          text-align: center;
          font-size: 20px;
          line-height: 24px;
          color: #757575;
          border-bottom: 1px solid #BDBDBD;
          padding-bottom: 16px; } }
      &_box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .price {
          display: inline-block;
          margin: 0 auto 40px;
          text-align: center;
          sup {
            font-family: "Rubik Medium";
            font-size: 32px;
            line-height: 40px;
            color: #757575;
            top: -34px; }
          span {
            font-size: 80px;
            line-height: 95px;
            font-weight: bold;
            color: #757575; } }
        .benefit-list {
          ul {
            padding-left: 0;
            margin-bottom: 40px;
            li {
              text-align: left;
              margin-bottom: 8px;
              display: flex;
              align-items: flex-start;
              &:last-child {
                margin-bottom: 0; }
              &.disabled {
                span {
                  color: #BDBDBD; } }
              .ic {
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                img,
                .svg {
                  display: block;
                  width: 100%;
                  height: 100%; } }
              span {
                display: inline-block;
                vertical-align: middle;
                margin-left: 8px;
                color: #616161; } } } }

        .box-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .btn-transparent {
            color: #757575;
            border-color: #757575; }
          .btn-link {
            color: #757575; }
          .btn + .btn {
            margin-top: 24px; } } } } } }

.simple-steps {
  .aksen-4 {
    top: auto;
    left: auto;
    right: 0;
    bottom: -80px;
    transform: rotate(91deg); }
  .section__heading_waksen {
    margin-bottom: 60px; }
  .col-md-4 {
    position: relative;
    display: flex;
    & + .col-md-4 {}
    &:nth-child(2n) {
      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 8px);
        left: -60px;
        border: 2px dashed #77A1CA;
        height: 1px;
        width: 88px; }
      .simple-steps_card {
        margin: 0 auto; } }
    &:nth-child(3n) {
      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 8px);
        left: -30px;
        border: 2px dashed #77A1CA;
        height: 1px;
        width: 88px; }
      justify-content: flex-end; } }

  &_card {
    background: rgba(50,118,178,0.1);
    border-radius: 8px;
    padding: 32px 24px;
    text-align: center;
    height: 100%;
    max-width: 328px;
    .icon {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
      margin-bottom: 24px;
      img {
        width: 100%;
        height: 100%; } }
    .text {
      h4 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px; } } } }

// CommingSoon Section
.block-commingsoon {
  padding: rem(120) 0;
  .box-commingsoon {
    padding: rem(50);
    background: #FFF;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 30px;
    min-height: rem(154);
    h2 {
      font-size: rem(24);
      line-height: rem(44);
      @include font-500;
      margin-bottom: 0; } } }
