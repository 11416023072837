/* ------------------------------------------------------------------------------------------------ */
/* Typography */
/* ------------------------------------------------------------------------------------------------ */

p {
  margin: 0 0 1.5rem;
  padding: 0;
  &:last-child {
    margin: 0; }
  strong,b {
    font-weight: 700; }
  em,i {
    font-style: italic; }
  u {
    text-decoration: underline; }
  a:hover {
    text-decoration: underline; }
  &:last-child {
    margin-bottom: 0; } }

h1, h2, h3, h4 {
  margin: 0 0  rem(20);
  font-weight: 600;
  color: $dark;
  @include font-600; }

h1 {
  font-size: rem(50);
  line-height: rem(60);
  @include font-700; }

h2 {
  font-size: rem(40);
  line-height: rem(45);
  @include font-700; }

h3 {
  font-size: 1.5rem;
  line-height: 2em; }

h4 {
  font-size: 1rem;
  line-height: 1.5em; }

article {
  p {
    word-break: keep-all;
    /* Non standard for webkit */
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    margin: 0 0 1.25rem;
    &:last-child {
      margin: 0; }
    &.upper {
      text-transform: uppercase; }
    &.big {
      font-size: rem(20);
      line-height: rem(30); } }
  a {
    &:hover {
      text-decoration: underline; } }

  blockquote {
    font-style: italic; }

  li {
    ul, ol {
      margin: 0 1.25rem;
      padding-top: 0.625rem; } }

  ul, ol {
    margin: 0 0 1.875rem;
    padding-left: 1.5625rem;
    &:last-child {
      margin: 0; }
    li {
      margin-bottom: 0.625rem;
      &:last-child {
        margin: 0; } } }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } } }

.clearfix::after {
  content: "";
  clear: both;
  display: table; }

.text-center,
%text-center {
  text-align: center; }

.text-right,
%text-right {
  text-align: right; }

.text-left,
%text-left {
  text-align: left; }

.text-bold,
%text-bold {
  font-weight: 700; }

.text-semibold,
%text-semibold {
  font-weight: 600; }

.text-regular,
%text-regular {
  font-weight: 400; }

.text-light,
%text-light {
  font-weight: 300; }

.text-capital,
%text-capital {
  text-transform: uppercase; }

.mb {
  &-0 {
    margin-bottom: 0; }
  &-20 {
    margin-bottom: rem(20); }
  &-40 {
    margin-bottom: rem(40); }
  &-80 {
    margin-bottom: rem(40); } }


/* ------------------------------------------------------------------------------------------------ */
/* BASE */
/* ------------------------------------------------------------------------------------------------ */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%; }

hr {
  border-top: 1px solid $border; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }


html, body {
  font-size: 14px;
  line-height: 21px;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  background-color: #FFFFFF;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }
