/* ------------------------------------------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------------------------------------------ */

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 150;
  transition: all .3s linear;
  background-color: transparent;
  &-wrap {
    transition: all .3s linear;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: rem(24);
    padding-bottom: rem(14); }
  &-left {
    flex: 0 0 16%;
    max-width: 16%; }
  &-logo {
    transition: all .3s linear;
    img {
      height: rem(80);
      transition: all .3s linear;
      &.colored {
        display: none; }
      @include mobile {
        height: auto;
        max-width: 100px; } } }
  &-right {
    flex: 0 0 84%;
    max-width: 84%; }
  &-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: rem(16);
    .nav-item {
      transition: all .3s ease-in-out;
      position: relative;
      padding: rem(10);
      a {
        text-transform: capitalize;
        color: #FFF;
        white-space: nowrap;
        @include font-400;
        &:hover {
          text-decoration: none; } } }
    & > .nav-item {
      &:hover,
      &.active {
        &:before {
          content: "";
          width: 100%;
          height: 1px;
          background: #FFF;
          position: absolute;
          left: 0;
          top: 100%; } }
      &.has-sub {
        position: relative;
        padding-right: rem(32);
        &:after {
          content: "";
          position: absolute;
          top: calc(50% - 5px);
          right: 0;
          @include triangle('down', 14px, 9px, #FFF); }
        @include responsive(0, 1301px) {
          &:hover {
            .sub-menu {
              top: calc(100% + 15px);
              opacity: 1;
              visibility: visible; } } }
        .sub-menu {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          min-width: rem(240);
          width: max-content;
          background: #FFF;
          padding: rem(20);
          border-radius: 16px;
          box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
          opacity: 0;
          visibility: hidden;
          transition: all .3s linear;
          &:before {
            content: "";
            width: 100%;
            height: rem(20);
            position: absolute;
            bottom: 100%;
            left: 0; }
          .nav-item {
            padding: 0;
            &:not(:last-child) {
              margin-bottom: rem(10); }
            a {
              color: $primary;
              transition: all .3s ease-in-out;
              &:hover {
                opacity: .8; } } } }
        @include responsive(1300px, 0) {
          &:after {
            display: none; }
          & > a {
            position: relative;
            max-width: max-content;
            &:after {
              content: "";
              position: absolute;
              top: 5px;
              left: calc(100% + 10px);
              @include triangle('down', 14px, 9px, $primary); } }
          .sub-menu {
            padding: 0;
            background: transparent;
            box-shadow: none; }
          &.sm-opened {
            .sub-menu {
              position: relative;
              visibility: visible;
              opacity: 1;
              margin-top: rem(16);
              .nav-item:not(:last-child) {
                margin-bottom: 0; } } } } } } }
  &.header-secondary,
  &.sticky {
    background: #FFF;
    .header {
      &-wrap {
        padding: rem(10) 0; }
      &-logo {
        img {
          height: rem(60);
          &:not(.colored) {
            display: none; }
          &.colored {
            display: block; } } }
      &-nav {
        .nav-item {
          a {
            color: #0083CB; }
          &.has-sub {
            &:after {
              @include triangle('down', 14px, 9px, #0083CB); } }
          &:hover,
          &.active {
            &:before {
              background: #0083CB; } } } } } }


  @include responsive(0, 1301px) {
    .burger-menu {
      display: none; } } }

// +maxtablet
@include responsive(1300px, 0) {
  .burger-menu {
    width: rem(32);
    margin: 0 auto;
    cursor: pointer;
    height: rem(32);
    z-index: 5;
    transition: .25s ease-in-out;
    transition-delay: .25s;
    position: absolute;
    z-index: 99;
    top: calc(50% - #{rem(16)});
    right: rem(32);
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: rem(32);
      background: #FFF;
      border-radius: 20px;
      opacity: 1;
      left: 0;
      transform :rotate(0deg) {}
      //transition: opacity .5s .5s,top .5s .4s,transform .5s
      transition: all .4s ease;
      &:nth-child(1) {
        top: rem(5); }
      &:nth-child(2) {
        top: rem(15); }
      &:nth-child(3) {
        top: rem(25); } } }
  .header {
    &-wrap {
      padding: rem(16) 0; }
    &-right {
      position: fixed;
      top: rem(100);
      left: 0;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      background: #FFF;
      display: block;
      padding: 0 rem(20);
      max-height: 0;
      opacity: 0;
      visibility: hidden; }
    &-nav {
      display: block;
      margin-top: rem(32);
      max-height: 100%;
      overflow-y: auto;
      .nav-item {
        max-width: max-content;
        a {
          color: $primary; }
        &:not(:last-child) {
          margin-bottom: rem(20); }
        &.has-sub {
          &:after {
            @include triangle('down', 14px, 9px, $primary); } } } }
    &.sticky {
      .burger-menu {
        span {
          background: $primary; } }
      .header {
        &-logo {
          img {
            &:not(.colored) {
              display: none; }
            &.colored {
              display: block; } } }
        &-right {
          top: rem(70); } } } }


  body {
    &.main-menu-open {
      height: 100vh;
      overflow: hidden;
      &::after {
        opacity: 1;
        visibility: visible; }
      .header {
        background: #FFF;
        &-logo {
          img {
            &:not(.colored) {
              display: none; }
            &.colored {
              display: block; } } }
        &-right {
          max-height: calc(100vh - 60px);
          height: calc(100vh - 60px);
          opacity: 1;
          visibility: visible; }
        &-nav {
          padding-left: rem(52);
          @include mobile {
            padding-left: 0; } } }
      .burger-menu {
        top: rem(32);
        span {
          background: $primary;
          &:nth-child(1) {
            top: 6px;
            width: 100%;
            transform: rotate(-135deg); }
          &:nth-child(2) {
            top: 6px;
            width: 100%;
            opacity: 0; }
          &:nth-child(3) {
            top: 6px;
            width: 100%;
            transform: rotate(135deg); } } } } } }

