/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */

.home {
  &-masthead {
    min-height: rem(888);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .masthead-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      // img
      //   width: 100%
      //   height: 100%
      //   object-fit: cover
      //   object-position: center
      //   position: absolute
      //   top: 0
 }      //   left: 0
    .masthead-content {
      color: #fff;
      .date {
        font-size: rem(20);
        line-height: rem(24);
        margin-bottom: rem(10); }
      h1 {
        color: #FFF;
        @include font-600;
        margin-bottom: rem(30); }
      .countdown {
        margin-bottom: rem(30);
        &-wrap {
          padding-left: 5rem;
          padding-right: 5rem; }
        &-desc {
          margin-bottom: 1.5rem; }
        &-counter {
          display: flex;
          margin-right: -1.5rem;
          .cd {
            flex: 0 0 25%;
            max-width: 5.25rem;
            border-radius: 6px;
            margin-right: 1.5rem;
            &:not(:last-child) {
              margin-right: rem(80); }
            b {
              display: block;
              @include font-600;
              color: #FFF;
              font-size: rem(40);
              line-height: 1em;
              margin-bottom: rem(8); }
            span {
              color: #FFF;
              font-size: rem(25);
              line-height: rem(36); } } } } } }
  &-welcome {
    padding: rem(120) 0;
    background: #FFF;
    .welcome-card {
      height: 100%;
      padding: rem(50) rem(45);
      background: #FFF;
      border-radius: 30px;
      box-shadow:  0px 10px 50px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      .profile {
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(50);
        .pp {
          flex: 0 0 rem(100);
          max-width: rem(100);
          height: rem(100);
          border-radius: 100px;
          position: relative;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0; } }
        .pname {
          flex: 0 0 calc(100% - #{rem(100)});
          max-width: calc(100% - #{rem(100)});
          padding-left: rem(20);
          b {
            display: block;
            color: #000000;
            margin-bottom: rem(10); }
          span {
            font-style: italic;
            font-weight: 300;
            color: #000000;
            opacity: .8; } } }
      .welcome-text {
        color: #727272;
        margin-bottom: rem(50);
        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
          text-overflow: ellipsis; }

        .btn {
          display: block;
          max-width: max-content;
          margin: 0 auto; } }
      .profile-logo {
        margin-top: auto;
        img {
          display: block;
          width: auto;
          max-height: rem(114);
          margin: auto; } } } }
  &-glance {
    padding: rem(120) 0;
    background: #EEEEEE;
    .glance {
      &-img {
        width: 100%;
        padding: 13.4% 0;
        position: relative;
        margin-bottom: rem(50);
        img,
        video,
        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center;
          z-index: 3; }
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.15); } }
      &-title {
        font-size: rem(24);
        line-height: rem(44);
        text-align: center;
        @include font-600;
        margin-bottom: rem(30);
        color: #000000; }
      &-dateloc {
        width: 100%;
        max-width: rem(756);
        margin: 0 auto rem(30);
        background: #FFF;
        border-radius: 50px;
        display: flex;
        align-items: center;
        padding: rem(26) rem(32);
        .item {
          display: flex;
          align-items: center;
          flex: 0 0 50%;
          max-width: 50%;
          .icon {
            width: rem(40);
            height: rem(40);
            margin-right: rem(16); }
          span {
            color: #0083CB;
            @include font-500;
            font-size: rem(24);
            line-height: rem(36); } } }
      &-description {
        max-width: rem(600);
        margin: 0 auto;
        font-size: rem(20);
        line-height: rem(30);
        color: #333;
        text-align: center; } }
    .video-glance {
      position: relative;
      .btn-play {
        width: rem(80);
        height: rem(80);
        border-radius: 50px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background: url(../images/btn-video-play.svg) no-repeat center;
        background-size: contain;
        z-index: 5;
        transition: all .3s ease-in-out;
        &:hover {
          transform: scale(1.02); } } } }
  &-regis {
    padding: rem(120) 0;
    background: #FFF;
    .regis {
      &-box {
        width: 100%;
        padding: rem(40) rem(60) rem(60);
        background: #FFF;
        border-radius: 30px;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
        .btn-long {
          display: block;
          margin: 0 auto; } }
      &-title {
        @include font-500;
        text-align: center;
        margin-bottom: rem(50); }
      &-desc {
        max-width: rem(752);
        margin: 0 auto rem(50);
        text-align: center; }
      &-coups {
        padding: rem(95) 0;
        margin-bottom: rem(50);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .coupon {
          padding: rem(20) rem(50);
          background: #FFF;
          border-radius: 20px;
          h3 {
            @include font-500;
            font-size: rem(20);
            line-height: rem(44);
            margin-bottom: rem(10);
            color: $primary;
            text-align: center; }
          &-type {
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              width: rem(35);
              height: rem(35);
              margin-right: rem(20);
              img {
                object-fit: contain;
                width: 100%;
                height: 100%; } }
            span {
              @include font-600;
              color: $primary;
              font-size: rem(25);
              line-height: rem(44); } } } } } }
  &-timeline {
    .timeline-card {
      padding: rem(50);
      border-radius: 30px;
      background: #3B4676;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
      color: #FFF;
      height: 100%;
      h3 {
        color: #FFF;
        font-size: rem(30);
        line-height: rem(33);
        margin-bottom: rem(32);
        @include font-500; }
      ul {
        padding-left: 0;
        margin-bottom: rem(30);
        li {
          display: flex;
          align-items: center;
          position: relative;
          font-size: rem(18);
          line-height: rem(32);
          &:not(:last-child) {
            margin-bottom: rem(8); }
          b {
            // width: rem(207)
            width: max-content;
            position: relative; }
          span {
            padding-left: rem(8);
            &:before {
              content: ":";
              position: relative;
              left: -4px;
              top: 0; } } } }
      .btn-wrap {
        .btn + .btn {
          margin-left: rem(10); } } } }

  &-sponsorship {
    padding: rem(120) 0;
    .sponsor-logos {
      gap: rem(50);
      align-items: center;
      &:not(:last-child) {
        margin-bottom: rem(50); }
      img {
        max-height: rem(107);
        display: inline-block;
        vertical-align: middle; } } }

  @include maxtablet {
    &-masthead {
      .countdown {
        &-counter {
          flex-wrap: wrap; } } }
    &-welcome {
      .welcom-card {
        box-shadow: none; }
      .slider-md {
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1); } }
    &-regis {
      .regis-coups {
        display: block;
        .coupon {
          max-width: 80%;
          margin: 0 auto;
          &:not(:last-child) {
            margin-bottom: rem(24); } } } }
    &-timeline {
      .col-lg-6 {
        margin-bottom: rem(24); } }

    &-sponsorship {
        .sponsor-logos {
          gap: 0;
          .col {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            margin-bottom: rem(16);
            img {
              max-height: rem(40); } } } } }

  @include mobile {
    &-masthead {
      min-height: 50rem;
      h1 {
        font-size: 32px;
        line-height: 1.3em;
        br {
          display: none; } }
      .masthead-content {
        .countdown {
          &-counter {
            .cd {
              margin-bottom: rem(20); } } } } }
    &-welcome {
      .slider-md {
        margin-left: 0;
        margin-right: 0;
        border-radius: 30px;
        padding-bottom: rem(60);
        .owl-dots {
          margin-top: rem(0);
          position: absolute;
          left: 0;
          right: 0;
          bottom: rem(-30);
          margin: 0 auto; } }
      .welcome-card {
        box-shadow: none !important; } }
    &-glance {
      .glance {
        &-title {
          br {
            display: none; } }
        &-dateloc {
          display: block;
          .item {
            max-width: 100%;
            flex: 0 0 100%;
            &:not(:last-child) {
              margin-bottom: rem(16); }
            .icon {
              width: rem(24);
              height: rem(24); } } } } }
    &-regis {
      .regis {
        &-box {
          padding: rem(32); }
        &-coups {
          padding: rem(32) 0;
          .coupon {
            padding: rem(16);
            .coupon-type {
              span {
                font-size: rem(18);
                line-height: 1.2em; } } } } } }
    &-timeline {
      .btn-wrap {
        .btn {
          width: 100%;
          text-align: center;
          & + .btn {
            margin-left: 0 !important; }
          &:not(:last-child) {
            margin-bottom: rem(16); } } } }
    &-sponsorship {
      .sponsor-logos {
        .col {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: rem(16); } } } } }

.modalWelcome {
  .btn-close {
    position: absolute;
    top: rem(32);
    right: rem(32);
    z-index: 5;
    &:hover {
      cursor: pointer; } }
  .welcome-card {
    height: 100%;
    .profile {
      display: flex;
      align-items: flex-start;
      margin-bottom: rem(32);
      .pp {
        flex: 0 0 rem(100);
        max-width: rem(100);
        height: rem(100);
        border-radius: 100px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0; } }
      .pname {
        flex: 0 0 calc(100% - #{rem(100)});
        max-width: calc(100% - #{rem(100)});
        padding-left: rem(20);
        b {
          display: block;
          color: #000000;
          margin-bottom: rem(10); }
        span {
          font-style: italic;
          font-weight: 300;
          color: #000000;
          opacity: .8; } } }
    .welcome-text {
      color: #727272;
      margin-bottom: rem(50);
      p {
        font-size: rem(16);
        line-height: 1.5em; }

      .btn {
        display: block;
        max-width: max-content;
        margin: 0 auto; } }
    .profile-logo {
      img {
        display: block;
        width: auto;
        max-height: rem(114);
        margin: auto; } } } }

// About
.about {
  &-desc {
    padding: rem(120) 0;
    h2 {
      font-size: rem(24);
      line-height: rem(44);
      @include font-500;
      margin-bottom: rem(20); }
    article:not(:last-child) {
      margin-bottom: rem(60); }
    .box-img {
      width: 100%;
      height: auto;
      // max-height: rem(571)
      img {
        border-radius: 20px; } } }
  &-committee {
    padding: rem(120) 0;
    // background: #EEEEEE
    .committee-wrapper {}
    .committee-card {
      padding: rem(30);
      background: #FFF;
      border-radius: 30px;
      margin-bottom: rem(20);
      h3 {
        font-size: rem(24);
        line-height: rem(44);
        @include font-500();
        margin-bottom: 10px;
        text-transform: uppercase; }
      ul {
        padding-left: rem(30);
        li {
          position: relative;
          color: #000000;
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50px;
            position: absolute;
            top: 10px;
            left: -20px;
            background: #000; }
          &:not(:last-child) {
            margin-bottom: 10px; }
          .position {
            color: #3B4676;
            display: block; } } } }
    .committee-cardblue {
      padding: rem(40);
      border-radius: 20px;
      // background: #3B4676
      background: #EEEEEE;
      color: #333333; // #FFF
      &:not(:last-child) {
        margin-bottom: rem(10); }
      .icon {
        width: rem(50);
        height: rem(50);
        display: inline-block;
        vertical-align: middle;
        margin-right: rem(30);
        img {
          width: 100%;
          height: 100%; } }
      h3 {
        @include font-500;
        display: inline-block;
        vertical-align: middle;
        color: #3B4676; //#FFF
        margin-bottom: 0;
        text-transform: uppercase; }
      ul {
        list-style: none;
        padding-left: rem(30);
        li {
          position: relative;
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50px;
            position: absolute;
            top: 10px;
            left: -20px;
            background: #333333; } //#FFF
          &:not(:last-child) {
            margin-bottom: 10px; }
          .position {
            color: #333333; //#FFF
            display: block; } } } } }
  &-contact {
    padding: rem(120) 0;
    .contact-card {
      padding: rem(30);
      background: #FFF;
      border-radius: 30px;
      margin-bottom: rem(20);
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
      height: 100%;
      color: #727272;
      h3 {
        font-size: rem(24);
        line-height: rem(44);
        @include font-500();
        margin-bottom: 30px;
        text-transform: uppercase; }
      span {
        padding-right: rem(10); }
      a {
        color: #727272; }
      ul {
        list-style: none;
        padding-left: 0;
        li {
          ul {
            padding-left: rem(57); } } } } }
  &-chapoverview {
    padding: rem(120) 0;
    background: #EEEEEE;
    & > .container > .row:last-child {
      margin-top: rem(120); }
    .box-img {
      width: 100%;
      margin-bottom: rem(50);
      img {
        width: 100%;
        height: auto;
        max-height: rem(583); } }
    .box-legend {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(80);
      .item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .b-color {
          width: rem(35);
          height: rem(35);
          border-radius: 10px;
          margin-right: rem(10); }
        span {
          @include font-700;
          color: #000; } } }
    .box-member {
      padding: rem(30);
      background: #FFF;
      border-radius: 30px;
      text-align: center;
      max-width: rem(388);
      h3 {
        margin-bottom: 0;
        font-size: rem(26);
        line-height: 1.5em;
        @include font-500;
        text-transform: uppercase; } }
    article {
      margin-bottom: rem(50); } }


  @include mobile {
    &-contact {
      .contact-wrap {
        .col-md-6 {
          margin-bottom: rem(24); } } } } }

// Abstract
.abstract {
  &-content {
    padding: rem(120) 0;
    .dropdown.dropdown-nav {
      display: none; } }
  &-side {
    ul {
      max-width: rem(300);
      padding: 0;
      list-style: none;
      li {
        &:not(:last-child) {
          margin-bottom: rem(10); }
        a {
          display: block;
          padding: rem(20) rem(35);
          background: #EEEEEE;
          color: #333333;
          border-radius: 20px;
          transition: all .3s ease-in-out;
          &:hover {
            text-decoration: none;
            background: darken(#EEEEEE, 5%); } } } } }
  &-categories {
    &-desc {
      padding: rem(120) 0 rem(60);
      background: #FFF; }
    &-content {
      padding: rem(60) 0 rem(120);
      background: #EEEEEE;
      .box-categories {
        margin-bottom: rem(24);
        .box-title {
          padding: rem(20) rem(50);
          background: #3B4676;
          border-radius: 30px 30px 0 0;
          h3 {
            margin-bottom: 0;
            color: #FFF; } }

        .box-content {
          padding: rem(30) rem(40);
          background: #FFF;
          border-radius: 0 0 30px 30px;
          .custom-list {
            column-count: 2;
            li {
              margin-bottom: rem(8); } } } } } }
  @include mobile {
    &-side {
      ul {
        display: none; } }
    &-content {
      .dropdown.dropdown-nav {
        margin-bottom: rem(32); } }
    &-categories {
      &-content {
        .box-categories {
          .box-title {
            padding: rem(20) rem(24); }
          .box-content {
            padding: rem(20) rem(24);
            .custom-list {
              column-count: 1; } } } } } } }


// Login
.login {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; }
  .box-login {
    display: block;
    padding: rem(60);
    background: #FFF;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    transition: all .3s ease-in-out;
    box-shadow:  0px 10px 50px rgba(0, 0, 0, 0.1);
    &:hover {
      text-decoration: none;
      box-shadow:  0px 10px 50px rgba(0, 0, 0, 0.4); }
    .icon {
      height: rem(82);
      margin: 0 auto;
      margin-bottom: rem(30);
      img {
        width: auto;
        height: rem(82); } }
    .title {
      font-size: rem(40);
      line-height: rem(50);
      margin-bottom: rem(20); }
    p {
      color: #727272;
      max-width: 365px;
      margin: 0 auto; } }
  @include mobile {
    &-wrap {
      .col-md-6 {
        &:not(:last-child) {
          margin-bottom: rem(32); } }
      .box-login {
        padding: rem(30);
        .icon {
          height: rem(40);
          margin-bottom: rem(20);
          img {
            height: rem(40); } }
        .title {
          font-size: rem(32);
          line-height: 1.3; } } } } }


// Auth
.auth {
  min-height: 100vh;
  background: #FFF;
  .create-account,
  .signin,
  .signup {
    display: flex;
    align-items: center;
    min-height: 100vh;
    .box-auth {
      padding: rem(60);
      background: #FFF;
      border-radius: 30px;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
      .section-title {
        max-width: rem(532);
        margin: 0 auto rem(50);
        text-align: center; }
      .btn-wrap {
        text-align: center;
        margin-bottom: rem(50);
        .btn + .btn {
          margin-left: rem(30); } }
      .alredy {
        text-align: center; } }
    .auth-form {
      max-width: rem(676);
      margin: 0 auto;
      .form-action {
        margin-bottom: rem(50);
        .btn {
          margin-bottom: rem(20); } }
      .custom-checkbox {
        max-width: max-content;
        margin: 0 auto; } } }
  .signup {
    padding: rem(240) 0 rem(120);
    .box-auth {
      .section-title {
        max-width: 100%;
        h1 {
          font-size: rem(32);
          line-height: rem(44);
          @include font-500; } } }
    .auth-form {
      max-width: 100%;
      .keeplogin {
        margin-bottom: rem(50); } } } }

// Travel
.travel {
  article {
    h3 {
      font-size: rem(24);
      line-height: rem(44);
      @include font-500;
      margin-bottom: rem(20); } } }
.ta-content {
  padding: rem(120) 0;
  .section-title {
    h2 {
      margin-bottom: rem(30); }
    p {
      max-width: rem(900);
      margin: 0 auto;
      text-align: center; } }
  .section-subtitle {
    margin-bottom: rem(60);
    ul {
      li:not(:last-child) {
        margin-bottom: rem(30); } } }
  ol.boxgrey {
    margin: rem(60) 0; }

  &.explore-jakarta {
    background: #EEEEEE; } }

ol, ul {
  &.boxgrey {
    counter-reset: my-counter;
    list-style: none;
    padding-left: 0;
    & > li {
      padding: 30px;
      background: #EEEEEE;
      border-radius: 20px;
      a {
        color: #0083CB; }
      &:before {
        counter-increment: my-counter;
        content: counter(my-counter) ". "; }
      ul {
        padding-left: rem(30); } } } }

.hotel-list {
  .hotel-card {
    padding: rem(40);
    background: #FFFFFF;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: rem(50);
    &:not(:last-child) {
      margin-bottom: rem(24); }
    .thumbnail {
      width: rem(580);
      padding: 14.16% 0;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 20px; } }
    .content {
      width: 100%;
      max-width: calc(100% - #{rem(530)});
      h3 {
        margin-bottom: rem(10); }
      address {
        max-width: rem(480);
        margin-bottom: rem(20); } }
    .info {
      padding-top: rem(20);
      position: relative;
      border-top: 1px solid #000;
      max-width: rem(280);
      b {
        color: #333; } }
    .card-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; } } }

.transport-list {
  .transport-card {
    padding: rem(40);
    background: #FFFFFF;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: rem(50);
    &:not(:last-child) {
      margin-bottom: rem(24); }
    .thumbnail {
      width: rem(580);
      padding: 14.16% 0;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 20px; } }
    .content {
      width: 100%;
      max-width: calc(100% - #{rem(530)});
      h3 {
        margin-bottom: rem(10); }
      .desc {
        max-width: rem(515); } } } }

.destination-list {
  .row {
    margin-left: -10px;
    margin-right: -10px;
    .col-lg-4 {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: rem(20); } }
  .destination-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    .thumbnail {
      width: 100%;
      padding: 24.3% 0;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 20px 20px 0 0; } }
    .content {
      padding: rem(20);
      background: #FFF;
      height: inherit;
      border-radius: 0 0 20px 20px;
      h3.name {
        line-height: 1.5em;
        margin-bottom: 0; } } }

  .btn-wrap {
    margin-top: rem(50); } }


